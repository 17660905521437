<script setup>

import Dropdown from '@/components/DropdownV2.vue';
import CodaSignatureButtons from '@/components/CodaSignatureButtons.vue';
import ListRow from '@/components/ListRow.vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import ListItem from '@/components/ListItem.vue';
import { computed, ref } from 'vue';
import CodaSignaturePopup from '@/components/CodaSignaturePopup.vue';
import Popup from '@/clientcomponents/Popup.vue';
import CodaArchiveWarningModal from '@/components/CodaArchiveWarningModal.vue';
import Translated from '@/components/Translated.vue';

const props = defineProps({
    mandate: {
        type: Object,
        required: true,
    },
    clientV2: {
        type: Object,
        required: true,
    },
    isNew: {
        type: Boolean,
        required: true,
    },
});
const emits = defineEmits(['getCodaMandatesEvent']);

const showWarnings = ref(false);
const showPopup = ref(false);
const showArchiveMandatePopup = ref(false);
const hasClickedSignAction = ref(false);

const isTtmOnly = computed(() => props.mandate.bankAccounts.filter((ba) => ba.state !== 'archived').every((ba) => ba.flowState === 'draft-active-bank-mandate'));

function getCodaMandates () {
    emits('getCodaMandatesEvent');
}

function closePopup () {
    if (hasClickedSignAction.value) { // user MIGHT have signed
        getCodaMandates();
    }
    showPopup.value = false;
}

function stateReasonKey (reasonCode) {
    return {
        'R-0': 'lgnd-reason-r-00',
        'R-11': 'lgnd-reason-r-11',
        'R-12': 'lgnd-reason-r-12',
        'R-13': 'lgnd-reason-r-13',
        'R-14': 'lgnd-reason-r-14',
        'R-16': 'lgnd-reason-r-16',
        'R-17': 'lgnd-reason-r-17',
        'R-18': 'lgnd-reason-r-18',
        'R-19': 'lgnd-reason-r-19',
        'R-20': 'lgnd-reason-r-20',
        'R-27': 'lgnd-reason-r-27',
        'R-28': 'lgnd-reason-r-28',
        'R-29': 'lgnd-reason-r-29',
        'R-31': 'lgnd-reason-r-31',
        'R-32': 'lgnd-reason-r-32',
        'R-33': 'lgnd-reason-r-33',
        'R-35': 'lgnd-reason-r-35',
        'R-36': 'lgnd-reason-r-36',
        'R-37': 'lgnd-reason-r-37',
        'R-38': 'lgnd-reason-r-38',
        'R-39': 'lgnd-reason-r-39',
        'R-40': 'lgnd-reason-r-40',
        'R-41': 'lgnd-reason-r-41',
        'R-42': 'lgnd-reason-r-42',
        'R-43': 'lgnd-reason-r-43',
        'R-44': 'lgnd-reason-r-44',
        'R-45': 'lgnd-reason-r-45',
        'R-46': 'lgnd-reason-r-46',
        'R-47': 'lgnd-reason-r-47',
        'R-48': 'lgnd-reason-r-48',
        'R-49': 'lgnd-reason-r-49',
        'R-50': 'lgnd-reason-r-50',
        'R-51': 'lgnd-reason-r-51',
        'R-52': 'lgnd-reason-r-52',
        'R-53': 'lgnd-reason-r-53',
    }[reasonCode] || 'lgnd-reason-r-00';
}

function signatureButtonClicked (buttonId) {
    switch (buttonId) {
        case 'copy-link':
        case 'download-mandate':
            showWarnings.value = true;
            break;
        case 'open-link':
        case 'open-download':
            showPopup.value = true;
            break;
    }
}

</script>

<template>
    <ListRow>
        <Popup :show='showPopup' :close='closePopup' small>
            <CodaSignaturePopup
                :mandate='mandate'
                :client-v2='clientV2'
                :close='closePopup'
                @has-clicked-sign-action='() => hasClickedSignAction = true'
            />
        </Popup>
        <CodaArchiveWarningModal
            v-if='showArchiveMandatePopup'
            :mandate='mandate'
            :close='() => showArchiveMandatePopup = false'
            @get-coda-mandates='getCodaMandates'
        />
        <ListItem>
            <div class='flex flex-col h-full py-3 whitespace-nowrap'>
                <div>
                    <span v-if='mandate.state === "problem"' class='bg-grey-300 mr-2 text-white font-semibold shadow-lg text-shadow-lg p-1 px-4 rounded-full text-xs'>{{ $t('state-problem') }}</span>
                    <span v-if='isNew' class='bg-orange-300 mr-2 text-white font-semibold shadow-lg text-shadow-lg p-1 px-4 rounded-full text-xs'>{{ $t('pill-new') }}</span>
                    {{ mandate.bank.name.toUpperCase() }}
                </div>
            </div>
        </ListItem>
        <ListItem>
            <template v-if='mandate.state === "problem"'>
                <span class='fa fa-info-circle'></span> {{ $t(stateReasonKey(mandate.reasonCode)) }}
            </template>
            <template v-if='showWarnings'>
                <div v-if='(mandate.bank.slug === "kbc" || mandate.bank.slug === "cbc") && !isTtmOnly'>
                    <span class='fa fa-info-circle'></span>
                    <Translated>
                        <template #en>
                            In addition to the signature required here, <b>{{ clientV2.representativeName }}</b> also needs to make the {{ mandate.bank.name }} link with Codabox. Just ask Kate or have a look at <a href='https://faq.codabox.com/en/support/solutions/articles/75000029777' target='_blank'>our FAQ</a>.
                        </template>
                        <template #nl>
                            Naast de handtekening die hier nodig is, moet <b>{{ clientV2.representativeName }}</b> ook de {{ mandate.bank.name }} koppeling met Codabox maken. Vraag het gewoon aan Kate of kijk in <a href='https://faq.codabox.com/nl/support/solutions/articles/75000029777' target='_blank'>onze FAQ</a>.
                        </template>
                        <template #fr>
                            Outre la signature requise ici, <b>{{ clientV2.representativeName }}</b> doit également établir le lien {{ mandate.bank.name }} avec Codabox. Demandez à Kate ou consultez <a href='https://faq.codabox.com/fr/support/solutions/articles/75000029777' target='_blank'>notre FAQ</a>.
                        </template>
                    </Translated>
                </div>
                <div v-else-if='mandate.bank.slug === "delta-lloyd" && !isTtmOnly'>
                    <span class='fa fa-info-circle mr-2'></span>
                    <Translated>
                        <template #en>
                            <b>{{ clientV2.representativeName }}</b> should sign all pages of the document and send a scanned copy to <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                        </template>
                        <template #nl>
                            <b>{{ clientV2.representativeName }}</b> moet alle pagina’s van het document teken en een gescande copie sturen naar <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                        </template>
                        <template #fr>
                            <b>{{ clientV2.representativeName }}</b> doit signer toutes les pages du document et envoyer une copie scannée à <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                        </template>
                    </Translated>
                </div>
            </template>
        </ListItem>
        <ListItem>
            <div class='flex flex-row-reverse items-center'>
                <Dropdown
                    direction='right'
                    transparent
                    no-margins
                    name='&#10247;'
                    icon=''
                >
                    <FriendlyButton
                        :action='() => showArchiveMandatePopup = true'
                        label='btn-archive'
                        square
                        extra-small
                        micro
                        no-margin
                        :id='`codaCardArchiveCodaMandateButton_${mandate.id}`'
                    />
                </Dropdown>
                <CodaSignatureButtons
                    class='min-w-44 mr-4'
                    :mandate='mandate'
                    new-flow
                    @refresh-coda-mandates='getCodaMandates'
                    @button-clicked='signatureButtonClicked'
                />
            </div>
        </ListItem>
    </ListRow>
</template>

<style scoped lang='postcss'>

</style>
