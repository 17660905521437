import humane from 'humane-js';
import 'humane-js/themes/flatty.css';

/*
 * Display popup notifications.
 *
 * Ex: import notify from './notify.js'; notify.info('foobar');
 *
 * See: http://wavded.github.io/humane-js/
 *
 */

const notify = humane.create({
    timeout: 2500,
    waitForMove: true,
    timeoutAfterMove: 250,
    clickToClose: true,
});

notify.baseCls = 'humane-flatty';
notify.success = notify.spawn({ addnCls: 'humane-flatty-success' });
notify.error   = notify.spawn({ addnCls: 'humane-flatty-error' });
notify.info    = notify.spawn({ addnCls: 'humane-flatty-info' });

export default notify;
